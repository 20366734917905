










































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { FETCH_OUTBOX, SET_OUTBOX_PAGE } from '../store';
import { PaginatableMixin } from '@/views/mixin';
import { Outbox, OutboxList } from '@/modules/correspondence/types';
import { ApiResponse, Pagination } from '@/components/types';
import ErrorAlert from '@/components/ErrorAlert.vue';
import ListSearchDialog from '@/components/ListSearchDialog.vue';
import CreateOutboxDialog from '../components/CreateOutboxDialog.vue';
import DeleteOutboxDialog from '../components/DeleteOutboxDialog.vue';
import MailLetterDialog from '../components/MailLetterDialog.vue';
import AppTable from '@/components/AppTable.vue';

const Correspondence = namespace('correspondence');

@Component({
  components: {
    AppTable,
    MailLetterDialog,
    DeleteOutboxDialog,
    ErrorAlert,
    ListSearchDialog,
    CreateOutboxDialog
  }
})
export default class OutboxView extends PaginatableMixin {
  @Correspondence.State('outbox') list!: OutboxList;
  @Correspondence.Action(FETCH_OUTBOX) fetch!: () => Promise<ApiResponse>;
  @Correspondence.Mutation(SET_OUTBOX_PAGE) setPage!: (page: number) => void;

  error = null;
  loading: boolean = false;

  changeOutbox: Outbox | null = null;
  deleteOutbox: Outbox | null = null;

  pagination: Pagination = {
    sortBy: ['mailed'],
    itemsPerPage: 10,
    page: 1,
    descending: true
  };

  get items(): Outbox[] {
    return this.list.items.map((item) => ({ ...item, mailedTrans: format(item.mailed, 'dd.MM.yyyy') }));
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.mailed'), value: 'mailed' },
      { align: 'left', text: this.$i18n.t('common.label'), value: 'description', width: '36%' },
      { align: 'left', text: this.$i18n.t('common.conservatee'), value: 'conservatorship.conservatee' },
      { align: 'left', text: this.$i18n.t('common.receiver'), value: 'receiver.name' },
      ...(this.isSupport() ? [
        { align: 'left', text: this.$i18n.t('common.creator'), value: null, sortable: false }
      ] : []),
      { align: 'left', text: this.$i18n.t('common.actions'), value: 'mailedTrans', sortable: false }
    ];
  }

  async created() {
    try {
      this.loading = true;

      await this.fetch();
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { MAIL_LETTER } from '../store';
import DatePicker from '@/components/form/DatePicker.vue';
import { Letter, MailLetterParams, Outbox } from '../types';
import { ApiResponse } from '@/components/types';
import { MapStateLetterToMailParams, MapStateOutboxToMailParams } from '../model';

const Correspondence = namespace('correspondence');

@Component({
  components: {
    DatePicker
  }
})
export default class MailLetterDialog extends Vue {
  @Prop({ type: Object }) outbox!: Outbox | null;
  @Prop({ type: Object }) letter!: Outbox | null;

  @Correspondence.Action(MAIL_LETTER) mailLetter!: (params: MailLetterParams) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;
  value: MailLetterParams | null = null;

  @Watch('dialog')
  watchDialogClosed(value?: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      // @ts-ignore
      this.$refs.form.reset();
      this.$emit('update:outbox', null);
      this.$emit('update:letter', null);
    }, 200);
  }

  @Watch('outbox')
  watchOutbox(outbox: Outbox) {
    if (!outbox) return;

    this.value = MapStateOutboxToMailParams(outbox);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  @Watch('letter')
  watchLetter(letter: Letter) {
    if (!letter) return;

    this.value = MapStateLetterToMailParams(letter);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    this.error = null;

    if (!this.value) return {};

    return this.mailLetter(this.value);
  }
}





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DELETE_OUTBOX } from '../store';
import { Outbox, WriteOutbox } from '../types';
import { MapStateOutboxToWriteOutbox } from '../model';
import { ApiResponse } from '@/components/types';

const Correspondence = namespace('correspondence');

@Component({})
export default class DeleteLetterDialog extends Vue {
  @Prop({ type: Object }) outbox!: Outbox | null;

  @Correspondence.Action(DELETE_OUTBOX) deleteOutbox!: (outbox: WriteOutbox) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  value: WriteOutbox | null = null;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:outbox', null);
    }, 200);
  }

  @Watch('outbox')
  watchOutbox(outbox: Outbox) {
    if (!outbox) return;

    this.value = MapStateOutboxToWriteOutbox(outbox);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  save() {
    if (!this.value) return;

    this.error = null;

    return this.deleteOutbox(this.value);
  }
}

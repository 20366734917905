































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { WriteOutbox } from '../types';
import { ADD_OUTBOX } from '../store';
import { ApiResponse } from '@/components/types';
import { createOutbox } from '@/modules/correspondence/model';
import DatePicker from '@/components/form/DatePicker.vue';
import ConservatorshipSelect from '@/modules/conservatorship/components/ConservatorshipSelect.vue';

const Correspondence = namespace('correspondence');

@Component({
  components: {
    DatePicker,
    ConservatorshipSelect
  }
})
export default class CreateOutboxDialog extends Vue {
  @Correspondence.Action(ADD_OUTBOX) addOutbox!: (outbox: WriteOutbox) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;

  outbox: WriteOutbox = createOutbox(this.$auth.user().id);

  cancel(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      this.outbox = createOutbox(this.$auth.user().id);
      this.error = null;

      observer && observer.reset();
    }, 200);
  }

  save() {
    this.error = null;

    return this.addOutbox(this.outbox);
  }
}
